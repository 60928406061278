






































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {
    Location: () => import('@/components/base/InsLocation.vue'),
    InsHomeRnp: () => import('@/components/business/home/InsHomeRNP.vue')
  }
})
export default class InsCmsContent extends Vue {
  CateName: string = '';
  content: object = {};
  addclass = false;
  Architecture = false;
  formtecture = false;
  private waiting: boolean = true;

  // 获取关于我们cms内容
  getContent () {
    this.$Api.cms
      .getContentByDevice({ ContentId: this.id, IsMobile: this.isMobile })
      .then(result => {
        this.content = result.CMS;
        this.CateName = result.CMS.Name;
        this.waiting = false;
        // this.$HiddenLayer();
        console.log(result, 'CMS');

        this.$nextTick(() => {
          if (result.CMS.Title) document.title = result.CMS.Title;
          (document.getElementsByName('keywords')[0] as any).content =
            result.CMS.SeoKeyword;
          (document.getElementsByName('description')[0] as any).content =
            result.CMS.SeoDesc;
          (document.getElementsByName(
            'twitter:description'
          )[0] as any).content = result.CMS.SeoDesc;
          (document.getElementsByName('twitter:title')[0] as any).content =
            result.CMS.Title;
        });

        if (
          this.$route.path === '/cms/content/20300' ||
          this.$route.path === '/cms/content/20307' ||
          this.$route.path === '/cms/content/20310' ||
          this.$route.path === '/cms/content/20322' ||
          this.$route.path === '/cms/content/20301'
        ) {
          this.addclass = true;
        } else if (this.$route.path === '/cms/content/20309') {
          this.Architecture = true;
        } else if (this.$route.path === '/cms/content/20302') {
          this.formtecture = true;
        }
      });
  }

  get id () {
    return this.$route.params.id;
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }

  mounted () {
    this.getContent();
  }

  @Watch('isMobile', { deep: true })
  onMediaChange () {
    this.getContent();
  }

  @Watch('id', { deep: true })
  onKeyChange () {
    this.content = {};
    this.getContent();
  }
}
